// Components/Loading/Loading.js
import React from 'react';
import './Loading.scss'; // Certifique-se de ter um arquivo CSS para estilização

const Loading = () => {
    return (
        <div className='loading-overlay'>
            <img src='https://pizza-prime.s3.sa-east-1.amazonaws.com/website/loader_pizza_prime.gif' alt='Loading...' />
            <div className='text-l'>
                <span class="fade-in">Todo dia</span>
                <span class="fade-in">é dia</span>
                <span class="fade-in">de PIZZA</span>
            </div>
        </div>
    );
}

export default Loading;
